<template>
  <div>
    <v-card-actions v-if="!firstLoad">
      <v-spacer></v-spacer>
      <!--Refresh Button-->
      <v-btn
        text
        x-large
        v-if="isNew"
        v-show="checkFormInputs()"
        @click="passRefresh()"
      >
        <v-icon left small>mdi-refresh</v-icon>
        Refresh
      </v-btn>
      <!--Cancel Button-->
      <v-btn @click="$router.back()" color="black" text x-large>
        <v-icon left>mdi-close</v-icon>Cancel
      </v-btn>
      <!--Delete Button-->
      <v-btn
        color="#ce2458"
        text
        x-large
        @click.stop="$emit('openDeleteDialog', true)"
        v-if="!isNew"
        :loading="loading"
      >
        <v-icon left>mdi-delete</v-icon>
        Delete
      </v-btn>
      <!--Add Button-->
      <v-btn
        color="#33cc99"
        type="submit"
        form="form"
        text
        x-large
        @click="form.action.add"
        :disabled="!valid"
        :loading="loading"
        v-if="isNew"
      >
        <v-icon left>mdi-check</v-icon>
        Submit
      </v-btn>
      <!--Edit Button-->
      <v-btn
        color="#33cc99"
        type="submit"
        form="form"
        text
        x-large
        @click="form.action.edit"
        :disabled="!valid"
        :loading="loading"
        v-if="!isNew"
      >
        <v-icon left>mdi-pencil</v-icon>
        Update
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
export default {
  name: "FormActions",
  props: ["form", "valid", "loading", "firstLoad", "isNew"],
  methods: {
    checkFormInputs() {
      // Loop through form inputs
      // If any of the values are not null return true
      // We can then display the "Refresh" button on the UI
      const inputs = this.form.fields
        .map((obj) => obj.inputs)
        .flat()
        .filter((e) => e.type !== "select");
      const inputValues = inputs.map((input) => input.value);
      return inputValues.some((el) => el !== null);
    },
    passRefresh() {
      this.$emit("passRefresh", null);
    },
  },
};
</script>